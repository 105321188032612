import React, { useState } from 'react'
import { Icon } from '../../../display/icon/icon'
import { FormElement, FormElementProperties } from '../../formelement/formelement'
import { RadioButton } from '../radiobutton/radiobutton'
import './radiogroup.scss'

export interface RadioGroupData<T> {
  icon?: string;
  label: string;
  id: string;
  data?: T;
}

export interface RadioGroupProps<T> extends FormElementProperties {
  data: Array<RadioGroupData<T>>;
  disabled: boolean;
  selectedId: string | null;
  onSelectionChanged?: (id: string, data: T | string) => void;
  className?: string;
}

export const RadioGroup = <T, >({
  id,
  label,
  hint,
  signal,
  data,
  disabled,
  selectedId,
  onSelectionChanged,
  className = '',
  ...rest
}: RadioGroupProps<T>) => {
  const [realId] = useState(id || `radio-group-${Math.round(Math.random() * 100000000)}`)
  return (
    <FormElement id={realId} label={label} hint={hint} signal={signal} className={`bepe-radiogroup ${className}`}>
      <div className={`bepe-radiogroup__container ${className}`} {...rest}>
        {data.map(d => {
          return <RadioButton disabled={disabled} key={d.id} radioId={d.id} data={d.data} onSelected={(key, value) => onSelectionChanged(key, value)} radioGroup={realId} selected={selectedId === d.id}>
            <div className="bepe-radiogroup__element">
              {d.icon && <Icon icon={d.icon}></Icon>}
              {d.label}
            </div>
          </RadioButton>
        })}
      </div>
    </FormElement>
  )
}
