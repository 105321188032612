import React, { HTMLProps, ReactNode } from 'react'
import { TextSize } from '../../util/size'
import { Status } from '../../util/status'

import './badge.scss'

export interface BadgeProps extends HTMLProps<HTMLDivElement> {
  children: ReactNode;
  status?: Status;
  textSize?: TextSize;
  className?: string;
}

export const Badge = ({
  children,
  status = Status.OK,
  textSize = TextSize.M,
  className = '',
  ...props
}: BadgeProps) => {
  return (
    <div
      {...props}
      className={`static bepe-badge bepe-badge--${status} bepe-badge--${textSize} ${className}`}
    >
      {children}
    </div>
  )
}
