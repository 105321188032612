import React, { ButtonHTMLAttributes, MouseEventHandler, ReactNode } from 'react'
import { Spinner } from '../../state/spinner/spinner'
import './button.scss'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  classtype?: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'accent';
  size?: 'small' | 'medium';
  busy?: boolean;
  className?: string;
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

/**
 * Primary UI component for user interaction
 */
export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(function Button ({
  classtype = 'secondary',
  className,
  size = 'medium',
  busy = false,
  children,
  ...props
}: ButtonProps, ref): JSX.Element {
  return (
    <button
      {...props}
      ref={ref}
      className={`bepe-button interactive bepe-button--${busy ? 'busy' : 'not-busy'} bepe-button--${classtype} bepe-button--${size} ${className}`}
      disabled={props.disabled || busy}
    >
      {busy && <Spinner sizeAsString={size === 'small' ? '1.6rem' : '2.4rem'}></Spinner>}
      {children}
    </button>
  )
})
