export enum FileType {
  JSON = 'json',
  CSV = 'csv',
}

export function downloadAsFile (data: string, fileName: string, type: FileType) {
  const dataStr = `data:text/${type};charset=utf-8,` + encodeURIComponent(data)
  const downloadAnchorNode = document.createElement('a')
  downloadAnchorNode.setAttribute('href', dataStr)
  downloadAnchorNode.setAttribute('download', `${fileName}.${type}`)
  document.body.appendChild(downloadAnchorNode) // required for firefox
  downloadAnchorNode.click()
  downloadAnchorNode.remove()
}
