export function readFile (file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    if (file) {
      const fileReader = new FileReader()
      fileReader.onload = () => {
        resolve(fileReader.result as string)
      }
      fileReader.onerror = () => {
        reject(fileReader.error)
      }
      fileReader.readAsText(file)
    }
  })
}
