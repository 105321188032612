export enum Icons {
  _0 = '\u0030',
  _1 = '\u0031',
  _2 = '\u0032',
  _3 = '\u0033',
  _4 = '\u0034',
  _42Group = '\ue080',
  _5 = '\u0035',
  _500px = '\uf26e',
  _6 = '\u0036',
  _7 = '\u0037',
  _8 = '\u0038',
  _9 = '\u0039',
  A = '\u0041',
  AccessibleIcon = '\uf368',
  Accusoft = '\uf369',
  AddressBook = '\uf2b9',
  AddressCard = '\uf2bb',
  Adn = '\uf170',
  Adversal = '\uf36a',
  Affiliatetheme = '\uf36b',
  Airbnb = '\uf834',
  Algolia = '\uf36c',
  AlignCenter = '\uf037',
  AlignJustify = '\uf039',
  AlignLeft = '\uf036',
  AlignRight = '\uf038',
  Alipay = '\uf642',
  Amazon = '\uf270',
  AmazonPay = '\uf42c',
  Amilia = '\uf36d',
  Anchor = '\uf13d',
  AnchorCircleCheck = '\ue4aa',
  AnchorCircleExclamation = '\ue4ab',
  AnchorCircleXmark = '\ue4ac',
  AnchorLock = '\ue4ad',
  Android = '\uf17b',
  Angellist = '\uf209',
  AngleDown = '\uf107',
  AngleLeft = '\uf104',
  AngleRight = '\uf105',
  AngleUp = '\uf106',
  AnglesDown = '\uf103',
  AnglesLeft = '\uf100',
  AnglesRight = '\uf101',
  AnglesUp = '\uf102',
  Angrycreative = '\uf36e',
  Angular = '\uf420',
  Ankh = '\uf644',
  AppStore = '\uf36f',
  AppStoreIos = '\uf370',
  Apper = '\uf371',
  Apple = '\uf179',
  ApplePay = '\uf415',
  AppleWhole = '\uf5d1',
  Archway = '\uf557',
  ArrowDown = '\uf063',
  ArrowDown19 = '\uf162',
  ArrowDown91 = '\uf886',
  ArrowDownAZ = '\uf15d',
  ArrowDownLong = '\uf175',
  ArrowDownShortWide = '\uf884',
  ArrowDownUpAcrossLine = '\ue4af',
  ArrowDownUpLock = '\ue4b0',
  ArrowDownWideShort = '\uf160',
  ArrowDownZA = '\uf881',
  ArrowLeft = '\uf060',
  ArrowLeftLong = '\uf177',
  ArrowPointer = '\uf245',
  ArrowRight = '\uf061',
  ArrowRightArrowLeft = '\uf0ec',
  ArrowRightFromBracket = '\uf08b',
  ArrowRightLong = '\uf178',
  ArrowRightToBracket = '\uf090',
  ArrowRightToCity = '\ue4b3',
  ArrowRotateLeft = '\uf0e2',
  ArrowRotateRight = '\uf01e',
  ArrowTrendDown = '\ue097',
  ArrowTrendUp = '\ue098',
  ArrowTurnDown = '\uf149',
  ArrowTurnUp = '\uf148',
  ArrowUp = '\uf062',
  ArrowUp19 = '\uf163',
  ArrowUp91 = '\uf887',
  ArrowUpAZ = '\uf15e',
  ArrowUpFromBracket = '\ue09a',
  ArrowUpFromGroundWater = '\ue4b5',
  ArrowUpFromWaterPump = '\ue4b6',
  ArrowUpLong = '\uf176',
  ArrowUpRightDots = '\ue4b7',
  ArrowUpRightFromSquare = '\uf08e',
  ArrowUpShortWide = '\uf885',
  ArrowUpWideShort = '\uf161',
  ArrowUpZA = '\uf882',
  ArrowsDownToLine = '\ue4b8',
  ArrowsDownToPeople = '\ue4b9',
  ArrowsLeftRight = '\uf07e',
  ArrowsLeftRightToLine = '\ue4ba',
  ArrowsRotate = '\uf021',
  ArrowsSpin = '\ue4bb',
  ArrowsSplitUpAndLeft = '\ue4bc',
  ArrowsToCircle = '\ue4bd',
  ArrowsToDot = '\ue4be',
  ArrowsToEye = '\ue4bf',
  ArrowsTurnRight = '\ue4c0',
  ArrowsTurnToDots = '\ue4c1',
  ArrowsUpDown = '\uf07d',
  ArrowsUpDownLeftRight = '\uf047',
  ArrowsUpToLine = '\ue4c2',
  Artstation = '\uf77a',
  Asterisk = '\u002a',
  Asymmetrik = '\uf372',
  At = '\u0040',
  Atlassian = '\uf77b',
  Atom = '\uf5d2',
  Audible = '\uf373',
  AudioDescription = '\uf29e',
  AustralSign = '\ue0a9',
  Autoprefixer = '\uf41c',
  Avianex = '\uf374',
  Aviato = '\uf421',
  Award = '\uf559',
  Aws = '\uf375',
  B = '\u0042',
  Baby = '\uf77c',
  BabyCarriage = '\uf77d',
  Backward = '\uf04a',
  BackwardFast = '\uf049',
  BackwardStep = '\uf048',
  Bacon = '\uf7e5',
  Bacteria = '\ue059',
  Bacterium = '\ue05a',
  BagShopping = '\uf290',
  Bahai = '\uf666',
  BahtSign = '\ue0ac',
  Ban = '\uf05e',
  BanSmoking = '\uf54d',
  Bandage = '\uf462',
  Bandcamp = '\uf2d5',
  Barcode = '\uf02a',
  Bars = '\uf0c9',
  BarsProgress = '\uf828',
  BarsStaggered = '\uf550',
  Baseball = '\uf433',
  BaseballBatBall = '\uf432',
  BasketShopping = '\uf291',
  Basketball = '\uf434',
  Bath = '\uf2cd',
  BatteryEmpty = '\uf244',
  BatteryFull = '\uf240',
  BatteryHalf = '\uf242',
  BatteryQuarter = '\uf243',
  BatteryThreeQuarters = '\uf241',
  BattleNet = '\uf835',
  Bed = '\uf236',
  BedPulse = '\uf487',
  BeerMugEmpty = '\uf0fc',
  Behance = '\uf1b4',
  Bell = '\uf0f3',
  BellConcierge = '\uf562',
  BellSlash = '\uf1f6',
  BezierCurve = '\uf55b',
  Bicycle = '\uf206',
  Bilibili = '\ue3d9',
  Bimobject = '\uf378',
  Binoculars = '\uf1e5',
  Biohazard = '\uf780',
  Bitbucket = '\uf171',
  Bitcoin = '\uf379',
  BitcoinSign = '\ue0b4',
  Bity = '\uf37a',
  BlackTie = '\uf27e',
  Blackberry = '\uf37b',
  Blender = '\uf517',
  BlenderPhone = '\uf6b6',
  Blog = '\uf781',
  Blogger = '\uf37c',
  BloggerB = '\uf37d',
  Bluetooth = '\uf293',
  BluetoothB = '\uf294',
  Bold = '\uf032',
  Bolt = '\uf0e7',
  BoltLightning = '\ue0b7',
  Bomb = '\uf1e2',
  Bone = '\uf5d7',
  Bong = '\uf55c',
  Book = '\uf02d',
  BookAtlas = '\uf558',
  BookBible = '\uf647',
  BookBookmark = '\ue0bb',
  BookJournalWhills = '\uf66a',
  BookMedical = '\uf7e6',
  BookOpen = '\uf518',
  BookOpenReader = '\uf5da',
  BookQuran = '\uf687',
  BookSkull = '\uf6b7',
  BookTanakh = '\uf827',
  Bookmark = '\uf02e',
  Bootstrap = '\uf836',
  BorderAll = '\uf84c',
  BorderNone = '\uf850',
  BorderTopLeft = '\uf853',
  BoreHole = '\ue4c3',
  Bots = '\ue340',
  BottleDroplet = '\ue4c4',
  BottleWater = '\ue4c5',
  BowlFood = '\ue4c6',
  BowlRice = '\ue2eb',
  BowlingBall = '\uf436',
  Box = '\uf466',
  BoxArchive = '\uf187',
  BoxOpen = '\uf49e',
  BoxTissue = '\ue05b',
  BoxesPacking = '\ue4c7',
  BoxesStacked = '\uf468',
  Braille = '\uf2a1',
  Brain = '\uf5dc',
  BrazilianRealSign = '\ue46c',
  BreadSlice = '\uf7ec',
  Bridge = '\ue4c8',
  BridgeCircleCheck = '\ue4c9',
  BridgeCircleExclamation = '\ue4ca',
  BridgeCircleXmark = '\ue4cb',
  BridgeLock = '\ue4cc',
  BridgeWater = '\ue4ce',
  Briefcase = '\uf0b1',
  BriefcaseMedical = '\uf469',
  Broom = '\uf51a',
  BroomBall = '\uf458',
  Brush = '\uf55d',
  Btc = '\uf15a',
  Bucket = '\ue4cf',
  Buffer = '\uf837',
  Bug = '\uf188',
  BugSlash = '\ue490',
  Bugs = '\ue4d0',
  Building = '\uf1ad',
  BuildingCircleArrowRight = '\ue4d1',
  BuildingCircleCheck = '\ue4d2',
  BuildingCircleExclamation = '\ue4d3',
  BuildingCircleXmark = '\ue4d4',
  BuildingColumns = '\uf19c',
  BuildingFlag = '\ue4d5',
  BuildingLock = '\ue4d6',
  BuildingNgo = '\ue4d7',
  BuildingShield = '\ue4d8',
  BuildingUn = '\ue4d9',
  BuildingUser = '\ue4da',
  BuildingWheat = '\ue4db',
  Bullhorn = '\uf0a1',
  Bullseye = '\uf140',
  Burger = '\uf805',
  Buromobelexperte = '\uf37f',
  Burst = '\ue4dc',
  Bus = '\uf207',
  BusSimple = '\uf55e',
  BusinessTime = '\uf64a',
  BuyNLarge = '\uf8a6',
  Buysellads = '\uf20d',
  C = '\u0043',
  CableCar = '\uf7da',
  CakeCandles = '\uf1fd',
  Calculator = '\uf1ec',
  Calendar = '\uf133',
  CalendarCheck = '\uf274',
  CalendarDay = '\uf783',
  CalendarDays = '\uf073',
  CalendarMinus = '\uf272',
  CalendarPlus = '\uf271',
  CalendarWeek = '\uf784',
  CalendarXmark = '\uf273',
  Camera = '\uf030',
  CameraRetro = '\uf083',
  CameraRotate = '\ue0d8',
  Campground = '\uf6bb',
  CanadianMapleLeaf = '\uf785',
  CandyCane = '\uf786',
  Cannabis = '\uf55f',
  Capsules = '\uf46b',
  Car = '\uf1b9',
  CarBattery = '\uf5df',
  CarBurst = '\uf5e1',
  CarOn = '\ue4dd',
  CarRear = '\uf5de',
  CarSide = '\uf5e4',
  CarTunnel = '\ue4de',
  Caravan = '\uf8ff',
  CaretDown = '\uf0d7',
  CaretLeft = '\uf0d9',
  CaretRight = '\uf0da',
  CaretUp = '\uf0d8',
  Carrot = '\uf787',
  CartArrowDown = '\uf218',
  CartFlatbed = '\uf474',
  CartFlatbedSuitcase = '\uf59d',
  CartPlus = '\uf217',
  CartShopping = '\uf07a',
  CashRegister = '\uf788',
  Cat = '\uf6be',
  CcAmazonPay = '\uf42d',
  CcAmex = '\uf1f3',
  CcApplePay = '\uf416',
  CcDinersClub = '\uf24c',
  CcDiscover = '\uf1f2',
  CcJcb = '\uf24b',
  CcMastercard = '\uf1f1',
  CcPaypal = '\uf1f4',
  CcStripe = '\uf1f5',
  CcVisa = '\uf1f0',
  CediSign = '\ue0df',
  CentSign = '\ue3f5',
  Centercode = '\uf380',
  Centos = '\uf789',
  Certificate = '\uf0a3',
  Chair = '\uf6c0',
  Chalkboard = '\uf51b',
  ChalkboardUser = '\uf51c',
  ChampagneGlasses = '\uf79f',
  ChargingStation = '\uf5e7',
  ChartArea = '\uf1fe',
  ChartBar = '\uf080',
  ChartColumn = '\ue0e3',
  ChartGantt = '\ue0e4',
  ChartLine = '\uf201',
  ChartPie = '\uf200',
  ChartSimple = '\ue473',
  Check = '\uf00c',
  CheckDouble = '\uf560',
  CheckToSlot = '\uf772',
  Cheese = '\uf7ef',
  Chess = '\uf439',
  ChessBishop = '\uf43a',
  ChessBoard = '\uf43c',
  ChessKing = '\uf43f',
  ChessKnight = '\uf441',
  ChessPawn = '\uf443',
  ChessQueen = '\uf445',
  ChessRook = '\uf447',
  ChevronDown = '\uf078',
  ChevronLeft = '\uf053',
  ChevronRight = '\uf054',
  ChevronUp = '\uf077',
  Child = '\uf1ae',
  ChildDress = '\ue59c',
  ChildReaching = '\ue59d',
  ChildRifle = '\ue4e0',
  Children = '\ue4e1',
  Chrome = '\uf268',
  Chromecast = '\uf838',
  Church = '\uf51d',
  Circle = '\uf111',
  CircleArrowDown = '\uf0ab',
  CircleArrowLeft = '\uf0a8',
  CircleArrowRight = '\uf0a9',
  CircleArrowUp = '\uf0aa',
  CircleCheck = '\uf058',
  CircleChevronDown = '\uf13a',
  CircleChevronLeft = '\uf137',
  CircleChevronRight = '\uf138',
  CircleChevronUp = '\uf139',
  CircleDollarToSlot = '\uf4b9',
  CircleDot = '\uf192',
  CircleDown = '\uf358',
  CircleExclamation = '\uf06a',
  CircleH = '\uf47e',
  CircleHalfStroke = '\uf042',
  CircleInfo = '\uf05a',
  CircleLeft = '\uf359',
  CircleMinus = '\uf056',
  CircleNodes = '\ue4e2',
  CircleNotch = '\uf1ce',
  CirclePause = '\uf28b',
  CirclePlay = '\uf144',
  CirclePlus = '\uf055',
  CircleQuestion = '\uf059',
  CircleRadiation = '\uf7ba',
  CircleRight = '\uf35a',
  CircleStop = '\uf28d',
  CircleUp = '\uf35b',
  CircleUser = '\uf2bd',
  CircleXmark = '\uf057',
  City = '\uf64f',
  Clapperboard = '\ue131',
  Clipboard = '\uf328',
  ClipboardCheck = '\uf46c',
  ClipboardList = '\uf46d',
  ClipboardQuestion = '\ue4e3',
  ClipboardUser = '\uf7f3',
  Clock = '\uf017',
  ClockRotateLeft = '\uf1da',
  Clone = '\uf24d',
  ClosedCaptioning = '\uf20a',
  Cloud = '\uf0c2',
  CloudArrowDown = '\uf0ed',
  CloudArrowUp = '\uf0ee',
  CloudBolt = '\uf76c',
  CloudMeatball = '\uf73b',
  CloudMoon = '\uf6c3',
  CloudMoonRain = '\uf73c',
  CloudRain = '\uf73d',
  CloudShowersHeavy = '\uf740',
  CloudShowersWater = '\ue4e4',
  CloudSun = '\uf6c4',
  CloudSunRain = '\uf743',
  Cloudflare = '\ue07d',
  Cloudscale = '\uf383',
  Cloudsmith = '\uf384',
  Cloudversify = '\uf385',
  Clover = '\ue139',
  Cmplid = '\ue360',
  Code = '\uf121',
  CodeBranch = '\uf126',
  CodeCommit = '\uf386',
  CodeCompare = '\ue13a',
  CodeFork = '\ue13b',
  CodeMerge = '\uf387',
  CodePullRequest = '\ue13c',
  Codepen = '\uf1cb',
  Codiepie = '\uf284',
  Coins = '\uf51e',
  ColonSign = '\ue140',
  Comment = '\uf075',
  CommentDollar = '\uf651',
  CommentDots = '\uf4ad',
  CommentMedical = '\uf7f5',
  CommentSlash = '\uf4b3',
  CommentSms = '\uf7cd',
  Comments = '\uf086',
  CommentsDollar = '\uf653',
  CompactDisc = '\uf51f',
  Compass = '\uf14e',
  CompassDrafting = '\uf568',
  Compress = '\uf066',
  Computer = '\ue4e5',
  ComputerMouse = '\uf8cc',
  Confluence = '\uf78d',
  Connectdevelop = '\uf20e',
  Contao = '\uf26d',
  Cookie = '\uf563',
  CookieBite = '\uf564',
  Copy = '\uf0c5',
  Copyright = '\uf1f9',
  CottonBureau = '\uf89e',
  Couch = '\uf4b8',
  Cow = '\uf6c8',
  Cpanel = '\uf388',
  CreativeCommons = '\uf25e',
  CreativeCommonsBy = '\uf4e7',
  CreativeCommonsNc = '\uf4e8',
  CreativeCommonsNcEu = '\uf4e9',
  CreativeCommonsNcJp = '\uf4ea',
  CreativeCommonsNd = '\uf4eb',
  CreativeCommonsPd = '\uf4ec',
  CreativeCommonsPdAlt = '\uf4ed',
  CreativeCommonsRemix = '\uf4ee',
  CreativeCommonsSa = '\uf4ef',
  CreativeCommonsSampling = '\uf4f0',
  CreativeCommonsSamplingPlus = '\uf4f1',
  CreativeCommonsShare = '\uf4f2',
  CreativeCommonsZero = '\uf4f3',
  CreditCard = '\uf09d',
  CriticalRole = '\uf6c9',
  Crop = '\uf125',
  CropSimple = '\uf565',
  Cross = '\uf654',
  Crosshairs = '\uf05b',
  Crow = '\uf520',
  Crown = '\uf521',
  Crutch = '\uf7f7',
  CruzeiroSign = '\ue152',
  Css3 = '\uf13c',
  Css3Alt = '\uf38b',
  Cube = '\uf1b2',
  Cubes = '\uf1b3',
  CubesStacked = '\ue4e6',
  Cuttlefish = '\uf38c',
  D = '\u0044',
  DAndD = '\uf38d',
  DAndDBeyond = '\uf6ca',
  Dailymotion = '\ue052',
  Dashcube = '\uf210',
  Database = '\uf1c0',
  Deezer = '\ue077',
  DeleteLeft = '\uf55a',
  Delicious = '\uf1a5',
  Democrat = '\uf747',
  Deploydog = '\uf38e',
  Deskpro = '\uf38f',
  Desktop = '\uf390',
  Dev = '\uf6cc',
  Deviantart = '\uf1bd',
  Dharmachakra = '\uf655',
  Dhl = '\uf790',
  DiagramNext = '\ue476',
  DiagramPredecessor = '\ue477',
  DiagramProject = '\uf542',
  DiagramSuccessor = '\ue47a',
  Diamond = '\uf219',
  DiamondTurnRight = '\uf5eb',
  Diaspora = '\uf791',
  Dice = '\uf522',
  DiceD20 = '\uf6cf',
  DiceD6 = '\uf6d1',
  DiceFive = '\uf523',
  DiceFour = '\uf524',
  DiceOne = '\uf525',
  DiceSix = '\uf526',
  DiceThree = '\uf527',
  DiceTwo = '\uf528',
  Digg = '\uf1a6',
  DigitalOcean = '\uf391',
  Discord = '\uf392',
  Discourse = '\uf393',
  Disease = '\uf7fa',
  Display = '\ue163',
  Divide = '\uf529',
  Dna = '\uf471',
  Dochub = '\uf394',
  Docker = '\uf395',
  Dog = '\uf6d3',
  DollarSign = '\u0024',
  Dolly = '\uf472',
  DongSign = '\ue169',
  DoorClosed = '\uf52a',
  DoorOpen = '\uf52b',
  Dove = '\uf4ba',
  DownLeftAndUpRightToCenter = '\uf422',
  DownLong = '\uf309',
  Download = '\uf019',
  Draft2digital = '\uf396',
  Dragon = '\uf6d5',
  DrawPolygon = '\uf5ee',
  Dribbble = '\uf17d',
  Dropbox = '\uf16b',
  Droplet = '\uf043',
  DropletSlash = '\uf5c7',
  Drum = '\uf569',
  DrumSteelpan = '\uf56a',
  DrumstickBite = '\uf6d7',
  Drupal = '\uf1a9',
  Dumbbell = '\uf44b',
  Dumpster = '\uf793',
  DumpsterFire = '\uf794',
  Dungeon = '\uf6d9',
  Dyalog = '\uf399',
  E = '\u0045',
  EarDeaf = '\uf2a4',
  EarListen = '\uf2a2',
  Earlybirds = '\uf39a',
  EarthAfrica = '\uf57c',
  EarthAmericas = '\uf57d',
  EarthAsia = '\uf57e',
  EarthEurope = '\uf7a2',
  EarthOceania = '\ue47b',
  Ebay = '\uf4f4',
  Edge = '\uf282',
  EdgeLegacy = '\ue078',
  Egg = '\uf7fb',
  Eject = '\uf052',
  Elementor = '\uf430',
  Elevator = '\ue16d',
  Ellipsis = '\uf141',
  EllipsisVertical = '\uf142',
  Ello = '\uf5f1',
  Ember = '\uf423',
  Empire = '\uf1d1',
  Envelope = '\uf0e0',
  EnvelopeCircleCheck = '\ue4e8',
  EnvelopeOpen = '\uf2b6',
  EnvelopeOpenText = '\uf658',
  EnvelopesBulk = '\uf674',
  Envira = '\uf299',
  Equals = '\u003d',
  Eraser = '\uf12d',
  Erlang = '\uf39d',
  Ethereum = '\uf42e',
  Ethernet = '\uf796',
  Etsy = '\uf2d7',
  EuroSign = '\uf153',
  Evernote = '\uf839',
  Exclamation = '\u0021',
  Expand = '\uf065',
  Expeditedssl = '\uf23e',
  Explosion = '\ue4e9',
  Eye = '\uf06e',
  EyeDropper = '\uf1fb',
  EyeLowVision = '\uf2a8',
  EyeSlash = '\uf070',
  F = '\u0046',
  FaceAngry = '\uf556',
  FaceDizzy = '\uf567',
  FaceFlushed = '\uf579',
  FaceFrown = '\uf119',
  FaceFrownOpen = '\uf57a',
  FaceGrimace = '\uf57f',
  FaceGrin = '\uf580',
  FaceGrinBeam = '\uf582',
  FaceGrinBeamSweat = '\uf583',
  FaceGrinHearts = '\uf584',
  FaceGrinSquint = '\uf585',
  FaceGrinSquintTears = '\uf586',
  FaceGrinStars = '\uf587',
  FaceGrinTears = '\uf588',
  FaceGrinTongue = '\uf589',
  FaceGrinTongueSquint = '\uf58a',
  FaceGrinTongueWink = '\uf58b',
  FaceGrinWide = '\uf581',
  FaceGrinWink = '\uf58c',
  FaceKiss = '\uf596',
  FaceKissBeam = '\uf597',
  FaceKissWinkHeart = '\uf598',
  FaceLaugh = '\uf599',
  FaceLaughBeam = '\uf59a',
  FaceLaughSquint = '\uf59b',
  FaceLaughWink = '\uf59c',
  FaceMeh = '\uf11a',
  FaceMehBlank = '\uf5a4',
  FaceRollingEyes = '\uf5a5',
  FaceSadCry = '\uf5b3',
  FaceSadTear = '\uf5b4',
  FaceSmile = '\uf118',
  FaceSmileBeam = '\uf5b8',
  FaceSmileWink = '\uf4da',
  FaceSurprise = '\uf5c2',
  FaceTired = '\uf5c8',
  Facebook = '\uf09a',
  FacebookF = '\uf39e',
  FacebookMessenger = '\uf39f',
  Fan = '\uf863',
  FantasyFlightGames = '\uf6dc',
  Faucet = '\ue005',
  FaucetDrip = '\ue006',
  Fax = '\uf1ac',
  Feather = '\uf52d',
  FeatherPointed = '\uf56b',
  Fedex = '\uf797',
  Fedora = '\uf798',
  Ferry = '\ue4ea',
  Figma = '\uf799',
  File = '\uf15b',
  FileArrowDown = '\uf56d',
  FileArrowUp = '\uf574',
  FileAudio = '\uf1c7',
  FileCircleCheck = '\ue5a0',
  FileCircleExclamation = '\ue4eb',
  FileCircleMinus = '\ue4ed',
  FileCirclePlus = '\ue494',
  FileCircleQuestion = '\ue4ef',
  FileCircleXmark = '\ue5a1',
  FileCode = '\uf1c9',
  FileContract = '\uf56c',
  FileCsv = '\uf6dd',
  FileExcel = '\uf1c3',
  FileExport = '\uf56e',
  FileImage = '\uf1c5',
  FileImport = '\uf56f',
  FileInvoice = '\uf570',
  FileInvoiceDollar = '\uf571',
  FileLines = '\uf15c',
  FileMedical = '\uf477',
  FilePdf = '\uf1c1',
  FilePen = '\uf31c',
  FilePowerpoint = '\uf1c4',
  FilePrescription = '\uf572',
  FileShield = '\ue4f0',
  FileSignature = '\uf573',
  FileVideo = '\uf1c8',
  FileWaveform = '\uf478',
  FileWord = '\uf1c2',
  FileZipper = '\uf1c6',
  Fill = '\uf575',
  FillDrip = '\uf576',
  Film = '\uf008',
  Filter = '\uf0b0',
  FilterCircleDollar = '\uf662',
  FilterCircleXmark = '\ue17b',
  Fingerprint = '\uf577',
  Fire = '\uf06d',
  FireBurner = '\ue4f1',
  FireExtinguisher = '\uf134',
  FireFlameCurved = '\uf7e4',
  FireFlameSimple = '\uf46a',
  Firefox = '\uf269',
  FirefoxBrowser = '\ue007',
  FirstOrder = '\uf2b0',
  FirstOrderAlt = '\uf50a',
  Firstdraft = '\uf3a1',
  Fish = '\uf578',
  FishFins = '\ue4f2',
  Flag = '\uf024',
  FlagCheckered = '\uf11e',
  FlagUsa = '\uf74d',
  Flask = '\uf0c3',
  FlaskVial = '\ue4f3',
  Flickr = '\uf16e',
  Flipboard = '\uf44d',
  FloppyDisk = '\uf0c7',
  FlorinSign = '\ue184',
  Fly = '\uf417',
  Folder = '\uf07b',
  FolderClosed = '\ue185',
  FolderMinus = '\uf65d',
  FolderOpen = '\uf07c',
  FolderPlus = '\uf65e',
  FolderTree = '\uf802',
  Font = '\uf031',
  FontAwesome = '\uf2b4',
  Fonticons = '\uf280',
  FonticonsFi = '\uf3a2',
  Football = '\uf44e',
  FortAwesome = '\uf286',
  FortAwesomeAlt = '\uf3a3',
  Forumbee = '\uf211',
  Forward = '\uf04e',
  ForwardFast = '\uf050',
  ForwardStep = '\uf051',
  Foursquare = '\uf180',
  FrancSign = '\ue18f',
  FreeCodeCamp = '\uf2c5',
  Freebsd = '\uf3a4',
  Frog = '\uf52e',
  Fulcrum = '\uf50b',
  Futbol = '\uf1e3',
  G = '\u0047',
  GalacticRepublic = '\uf50c',
  GalacticSenate = '\uf50d',
  Gamepad = '\uf11b',
  GasPump = '\uf52f',
  Gauge = '\uf624',
  GaugeHigh = '\uf625',
  GaugeSimple = '\uf629',
  GaugeSimpleHigh = '\uf62a',
  Gavel = '\uf0e3',
  Gear = '\uf013',
  Gears = '\uf085',
  Gem = '\uf3a5',
  Genderless = '\uf22d',
  GetPocket = '\uf265',
  Gg = '\uf260',
  GgCircle = '\uf261',
  Ghost = '\uf6e2',
  Gift = '\uf06b',
  Gifts = '\uf79c',
  Git = '\uf1d3',
  GitAlt = '\uf841',
  Github = '\uf09b',
  GithubAlt = '\uf113',
  Gitkraken = '\uf3a6',
  Gitlab = '\uf296',
  Gitter = '\uf426',
  GlassWater = '\ue4f4',
  GlassWaterDroplet = '\ue4f5',
  Glasses = '\uf530',
  Glide = '\uf2a5',
  GlideG = '\uf2a6',
  Globe = '\uf0ac',
  Gofore = '\uf3a7',
  Golang = '\ue40f',
  GolfBallTee = '\uf450',
  Goodreads = '\uf3a8',
  GoodreadsG = '\uf3a9',
  Google = '\uf1a0',
  GoogleDrive = '\uf3aa',
  GooglePay = '\ue079',
  GooglePlay = '\uf3ab',
  GooglePlus = '\uf2b3',
  GooglePlusG = '\uf0d5',
  GoogleWallet = '\uf1ee',
  Gopuram = '\uf664',
  GraduationCap = '\uf19d',
  Gratipay = '\uf184',
  Grav = '\uf2d6',
  GreaterThan = '\u003e',
  GreaterThanEqual = '\uf532',
  Grip = '\uf58d',
  GripLines = '\uf7a4',
  GripLinesVertical = '\uf7a5',
  GripVertical = '\uf58e',
  Gripfire = '\uf3ac',
  GroupArrowsRotate = '\ue4f6',
  Grunt = '\uf3ad',
  GuaraniSign = '\ue19a',
  Guilded = '\ue07e',
  Guitar = '\uf7a6',
  Gulp = '\uf3ae',
  Gun = '\ue19b',
  H = '\u0048',
  HackerNews = '\uf1d4',
  Hackerrank = '\uf5f7',
  Hammer = '\uf6e3',
  Hamsa = '\uf665',
  Hand = '\uf256',
  HandBackFist = '\uf255',
  HandDots = '\uf461',
  HandFist = '\uf6de',
  HandHolding = '\uf4bd',
  HandHoldingDollar = '\uf4c0',
  HandHoldingDroplet = '\uf4c1',
  HandHoldingHand = '\ue4f7',
  HandHoldingHeart = '\uf4be',
  HandHoldingMedical = '\ue05c',
  HandLizard = '\uf258',
  HandMiddleFinger = '\uf806',
  HandPeace = '\uf25b',
  HandPointDown = '\uf0a7',
  HandPointLeft = '\uf0a5',
  HandPointRight = '\uf0a4',
  HandPointUp = '\uf0a6',
  HandPointer = '\uf25a',
  HandScissors = '\uf257',
  HandSparkles = '\ue05d',
  HandSpock = '\uf259',
  Handcuffs = '\ue4f8',
  Hands = '\uf2a7',
  HandsAslInterpreting = '\uf2a3',
  HandsBound = '\ue4f9',
  HandsBubbles = '\ue05e',
  HandsClapping = '\ue1a8',
  HandsHolding = '\uf4c2',
  HandsHoldingChild = '\ue4fa',
  HandsHoldingCircle = '\ue4fb',
  HandsPraying = '\uf684',
  Handshake = '\uf2b5',
  HandshakeAngle = '\uf4c4',
  HandshakeSimple = '\uf4c6',
  HandshakeSimpleSlash = '\ue05f',
  HandshakeSlash = '\ue060',
  Hanukiah = '\uf6e6',
  HardDrive = '\uf0a0',
  Hashnode = '\ue499',
  Hashtag = '\u0023',
  HatCowboy = '\uf8c0',
  HatCowboySide = '\uf8c1',
  HatWizard = '\uf6e8',
  HeadSideCough = '\ue061',
  HeadSideCoughSlash = '\ue062',
  HeadSideMask = '\ue063',
  HeadSideVirus = '\ue064',
  Heading = '\uf1dc',
  Headphones = '\uf025',
  HeadphonesSimple = '\uf58f',
  Headset = '\uf590',
  Heart = '\uf004',
  HeartCircleBolt = '\ue4fc',
  HeartCircleCheck = '\ue4fd',
  HeartCircleExclamation = '\ue4fe',
  HeartCircleMinus = '\ue4ff',
  HeartCirclePlus = '\ue500',
  HeartCircleXmark = '\ue501',
  HeartCrack = '\uf7a9',
  HeartPulse = '\uf21e',
  Helicopter = '\uf533',
  HelicopterSymbol = '\ue502',
  HelmetSafety = '\uf807',
  HelmetUn = '\ue503',
  Highlighter = '\uf591',
  HillAvalanche = '\ue507',
  HillRockslide = '\ue508',
  Hippo = '\uf6ed',
  Hips = '\uf452',
  HireAHelper = '\uf3b0',
  Hive = '\ue07f',
  HockeyPuck = '\uf453',
  HollyBerry = '\uf7aa',
  Hooli = '\uf427',
  Hornbill = '\uf592',
  Horse = '\uf6f0',
  HorseHead = '\uf7ab',
  Hospital = '\uf0f8',
  HospitalUser = '\uf80d',
  HotTubPerson = '\uf593',
  Hotdog = '\uf80f',
  Hotel = '\uf594',
  Hotjar = '\uf3b1',
  Hourglass = '\uf254',
  HourglassEnd = '\uf253',
  HourglassHalf = '\uf252',
  HourglassStart = '\uf251',
  House = '\uf015',
  HouseChimney = '\ue3af',
  HouseChimneyCrack = '\uf6f1',
  HouseChimneyMedical = '\uf7f2',
  HouseChimneyUser = '\ue065',
  HouseChimneyWindow = '\ue00d',
  HouseCircleCheck = '\ue509',
  HouseCircleExclamation = '\ue50a',
  HouseCircleXmark = '\ue50b',
  HouseCrack = '\ue3b1',
  HouseFire = '\ue50c',
  HouseFlag = '\ue50d',
  HouseFloodWater = '\ue50e',
  HouseFloodWaterCircleArrowRight = '\ue50f',
  HouseLaptop = '\ue066',
  HouseLock = '\ue510',
  HouseMedical = '\ue3b2',
  HouseMedicalCircleCheck = '\ue511',
  HouseMedicalCircleExclamation = '\ue512',
  HouseMedicalCircleXmark = '\ue513',
  HouseMedicalFlag = '\ue514',
  HouseSignal = '\ue012',
  HouseTsunami = '\ue515',
  HouseUser = '\ue1b0',
  Houzz = '\uf27c',
  HryvniaSign = '\uf6f2',
  Html5 = '\uf13b',
  Hubspot = '\uf3b2',
  Hurricane = '\uf751',
  I = '\u0049',
  ICursor = '\uf246',
  IceCream = '\uf810',
  Icicles = '\uf7ad',
  Icons = '\uf86d',
  IdBadge = '\uf2c1',
  IdCard = '\uf2c2',
  IdCardClip = '\uf47f',
  Ideal = '\ue013',
  Igloo = '\uf7ae',
  Image = '\uf03e',
  ImagePortrait = '\uf3e0',
  Images = '\uf302',
  Imdb = '\uf2d8',
  Inbox = '\uf01c',
  Indent = '\uf03c',
  IndianRupeeSign = '\ue1bc',
  Industry = '\uf275',
  Infinity = '\uf534',
  Info = '\uf129',
  Instagram = '\uf16d',
  Instalod = '\ue081',
  Intercom = '\uf7af',
  InternetExplorer = '\uf26b',
  Invision = '\uf7b0',
  Ioxhost = '\uf208',
  Italic = '\uf033',
  ItchIo = '\uf83a',
  Itunes = '\uf3b4',
  ItunesNote = '\uf3b5',
  J = '\u004a',
  Jar = '\ue516',
  JarWheat = '\ue517',
  Java = '\uf4e4',
  Jedi = '\uf669',
  JediOrder = '\uf50e',
  Jenkins = '\uf3b6',
  JetFighter = '\uf0fb',
  JetFighterUp = '\ue518',
  Jira = '\uf7b1',
  Joget = '\uf3b7',
  Joint = '\uf595',
  Joomla = '\uf1aa',
  Js = '\uf3b8',
  Jsfiddle = '\uf1cc',
  JugDetergent = '\ue519',
  K = '\u004b',
  Kaaba = '\uf66b',
  Kaggle = '\uf5fa',
  Key = '\uf084',
  Keybase = '\uf4f5',
  Keyboard = '\uf11c',
  Keycdn = '\uf3ba',
  Khanda = '\uf66d',
  Kickstarter = '\uf3bb',
  KickstarterK = '\uf3bc',
  KipSign = '\ue1c4',
  KitMedical = '\uf479',
  KitchenSet = '\ue51a',
  KiwiBird = '\uf535',
  Korvue = '\uf42f',
  L = '\u004c',
  LandMineOn = '\ue51b',
  Landmark = '\uf66f',
  LandmarkDome = '\uf752',
  LandmarkFlag = '\ue51c',
  Language = '\uf1ab',
  Laptop = '\uf109',
  LaptopCode = '\uf5fc',
  LaptopFile = '\ue51d',
  LaptopMedical = '\uf812',
  Laravel = '\uf3bd',
  LariSign = '\ue1c8',
  Lastfm = '\uf202',
  LayerGroup = '\uf5fd',
  Leaf = '\uf06c',
  Leanpub = '\uf212',
  LeftLong = '\uf30a',
  LeftRight = '\uf337',
  Lemon = '\uf094',
  Less = '\uf41d',
  LessThan = '\u003c',
  LessThanEqual = '\uf537',
  LifeRing = '\uf1cd',
  Lightbulb = '\uf0eb',
  Line = '\uf3c0',
  LinesLeaning = '\ue51e',
  Link = '\uf0c1',
  LinkSlash = '\uf127',
  Linkedin = '\uf08c',
  LinkedinIn = '\uf0e1',
  Linode = '\uf2b8',
  Linux = '\uf17c',
  LiraSign = '\uf195',
  List = '\uf03a',
  ListCheck = '\uf0ae',
  ListOl = '\uf0cb',
  ListUl = '\uf0ca',
  LitecoinSign = '\ue1d3',
  LocationArrow = '\uf124',
  LocationCrosshairs = '\uf601',
  LocationDot = '\uf3c5',
  LocationPin = '\uf041',
  LocationPinLock = '\ue51f',
  Lock = '\uf023',
  LockOpen = '\uf3c1',
  Locust = '\ue520',
  Lungs = '\uf604',
  LungsVirus = '\ue067',
  Lyft = '\uf3c3',
  M = '\u004d',
  Magento = '\uf3c4',
  Magnet = '\uf076',
  MagnifyingGlass = '\uf002',
  MagnifyingGlassArrowRight = '\ue521',
  MagnifyingGlassChart = '\ue522',
  MagnifyingGlassDollar = '\uf688',
  MagnifyingGlassLocation = '\uf689',
  MagnifyingGlassMinus = '\uf010',
  MagnifyingGlassPlus = '\uf00e',
  Mailchimp = '\uf59e',
  ManatSign = '\ue1d5',
  Mandalorian = '\uf50f',
  Map = '\uf279',
  MapLocation = '\uf59f',
  MapLocationDot = '\uf5a0',
  MapPin = '\uf276',
  Markdown = '\uf60f',
  Marker = '\uf5a1',
  Mars = '\uf222',
  MarsAndVenus = '\uf224',
  MarsAndVenusBurst = '\ue523',
  MarsDouble = '\uf227',
  MarsStroke = '\uf229',
  MarsStrokeRight = '\uf22b',
  MarsStrokeUp = '\uf22a',
  MartiniGlass = '\uf57b',
  MartiniGlassCitrus = '\uf561',
  MartiniGlassEmpty = '\uf000',
  Mask = '\uf6fa',
  MaskFace = '\ue1d7',
  MaskVentilator = '\ue524',
  MasksTheater = '\uf630',
  Mastodon = '\uf4f6',
  MattressPillow = '\ue525',
  Maxcdn = '\uf136',
  Maximize = '\uf31e',
  Mdb = '\uf8ca',
  Medal = '\uf5a2',
  Medapps = '\uf3c6',
  Medium = '\uf23a',
  Medrt = '\uf3c8',
  Meetup = '\uf2e0',
  Megaport = '\uf5a3',
  Memory = '\uf538',
  Mendeley = '\uf7b3',
  Menorah = '\uf676',
  Mercury = '\uf223',
  Message = '\uf27a',
  Meta = '\ue49b',
  Meteor = '\uf753',
  Microblog = '\ue01a',
  Microchip = '\uf2db',
  Microphone = '\uf130',
  MicrophoneLines = '\uf3c9',
  MicrophoneLinesSlash = '\uf539',
  MicrophoneSlash = '\uf131',
  Microscope = '\uf610',
  Microsoft = '\uf3ca',
  MillSign = '\ue1ed',
  Minimize = '\uf78c',
  Minus = '\uf068',
  Mitten = '\uf7b5',
  Mix = '\uf3cb',
  Mixcloud = '\uf289',
  Mixer = '\ue056',
  Mizuni = '\uf3cc',
  Mobile = '\uf3ce',
  MobileButton = '\uf10b',
  MobileRetro = '\ue527',
  MobileScreen = '\uf3cf',
  MobileScreenButton = '\uf3cd',
  Modx = '\uf285',
  Monero = '\uf3d0',
  MoneyBill = '\uf0d6',
  MoneyBill1 = '\uf3d1',
  MoneyBill1Wave = '\uf53b',
  MoneyBillTransfer = '\ue528',
  MoneyBillTrendUp = '\ue529',
  MoneyBillWave = '\uf53a',
  MoneyBillWheat = '\ue52a',
  MoneyBills = '\ue1f3',
  MoneyCheck = '\uf53c',
  MoneyCheckDollar = '\uf53d',
  Monument = '\uf5a6',
  Moon = '\uf186',
  MortarPestle = '\uf5a7',
  Mosque = '\uf678',
  Mosquito = '\ue52b',
  MosquitoNet = '\ue52c',
  Motorcycle = '\uf21c',
  Mound = '\ue52d',
  Mountain = '\uf6fc',
  MountainCity = '\ue52e',
  MountainSun = '\ue52f',
  MugHot = '\uf7b6',
  MugSaucer = '\uf0f4',
  Music = '\uf001',
  N = '\u004e',
  NairaSign = '\ue1f6',
  Napster = '\uf3d2',
  Neos = '\uf612',
  NetworkWired = '\uf6ff',
  Neuter = '\uf22c',
  Newspaper = '\uf1ea',
  NfcDirectional = '\ue530',
  NfcSymbol = '\ue531',
  Nimblr = '\uf5a8',
  Node = '\uf419',
  NodeJs = '\uf3d3',
  NotEqual = '\uf53e',
  NoteSticky = '\uf249',
  NotesMedical = '\uf481',
  Npm = '\uf3d4',
  Ns8 = '\uf3d5',
  Nutritionix = '\uf3d6',
  O = '\u004f',
  ObjectGroup = '\uf247',
  ObjectUngroup = '\uf248',
  OctopusDeploy = '\ue082',
  Odnoklassniki = '\uf263',
  OilCan = '\uf613',
  OilWell = '\ue532',
  OldRepublic = '\uf510',
  Om = '\uf679',
  Opencart = '\uf23d',
  Openid = '\uf19b',
  Opera = '\uf26a',
  OptinMonster = '\uf23c',
  Orcid = '\uf8d2',
  Osi = '\uf41a',
  Otter = '\uf700',
  Outdent = '\uf03b',
  P = '\u0050',
  Padlet = '\ue4a0',
  Page4 = '\uf3d7',
  Pagelines = '\uf18c',
  Pager = '\uf815',
  PaintRoller = '\uf5aa',
  Paintbrush = '\uf1fc',
  Palette = '\uf53f',
  Palfed = '\uf3d8',
  Pallet = '\uf482',
  Panorama = '\ue209',
  PaperPlane = '\uf1d8',
  Paperclip = '\uf0c6',
  ParachuteBox = '\uf4cd',
  Paragraph = '\uf1dd',
  Passport = '\uf5ab',
  Paste = '\uf0ea',
  Patreon = '\uf3d9',
  Pause = '\uf04c',
  Paw = '\uf1b0',
  Paypal = '\uf1ed',
  Peace = '\uf67c',
  Pen = '\uf304',
  PenClip = '\uf305',
  PenFancy = '\uf5ac',
  PenNib = '\uf5ad',
  PenRuler = '\uf5ae',
  PenToSquare = '\uf044',
  Pencil = '\uf303',
  PeopleArrows = '\ue068',
  PeopleCarryBox = '\uf4ce',
  PeopleGroup = '\ue533',
  PeopleLine = '\ue534',
  PeoplePulling = '\ue535',
  PeopleRobbery = '\ue536',
  PeopleRoof = '\ue537',
  PepperHot = '\uf816',
  Perbyte = '\ue083',
  Percent = '\u0025',
  Periscope = '\uf3da',
  Person = '\uf183',
  PersonArrowDownToLine = '\ue538',
  PersonArrowUpFromLine = '\ue539',
  PersonBiking = '\uf84a',
  PersonBooth = '\uf756',
  PersonBreastfeeding = '\ue53a',
  PersonBurst = '\ue53b',
  PersonCane = '\ue53c',
  PersonChalkboard = '\ue53d',
  PersonCircleCheck = '\ue53e',
  PersonCircleExclamation = '\ue53f',
  PersonCircleMinus = '\ue540',
  PersonCirclePlus = '\ue541',
  PersonCircleQuestion = '\ue542',
  PersonCircleXmark = '\ue543',
  PersonDigging = '\uf85e',
  PersonDotsFromLine = '\uf470',
  PersonDress = '\uf182',
  PersonDressBurst = '\ue544',
  PersonDrowning = '\ue545',
  PersonFalling = '\ue546',
  PersonFallingBurst = '\ue547',
  PersonHalfDress = '\ue548',
  PersonHarassing = '\ue549',
  PersonHiking = '\uf6ec',
  PersonMilitaryPointing = '\ue54a',
  PersonMilitaryRifle = '\ue54b',
  PersonMilitaryToPerson = '\ue54c',
  PersonPraying = '\uf683',
  PersonPregnant = '\ue31e',
  PersonRays = '\ue54d',
  PersonRifle = '\ue54e',
  PersonRunning = '\uf70c',
  PersonShelter = '\ue54f',
  PersonSkating = '\uf7c5',
  PersonSkiing = '\uf7c9',
  PersonSkiingNordic = '\uf7ca',
  PersonSnowboarding = '\uf7ce',
  PersonSwimming = '\uf5c4',
  PersonThroughWindow = '\ue5a9',
  PersonWalking = '\uf554',
  PersonWalkingArrowLoopLeft = '\ue551',
  PersonWalkingArrowRight = '\ue552',
  PersonWalkingDashedLineArrowRight = '\ue553',
  PersonWalkingLuggage = '\ue554',
  PersonWalkingWithCane = '\uf29d',
  PesetaSign = '\ue221',
  PesoSign = '\ue222',
  Phabricator = '\uf3db',
  PhoenixFramework = '\uf3dc',
  PhoenixSquadron = '\uf511',
  Phone = '\uf095',
  PhoneFlip = '\uf879',
  PhoneSlash = '\uf3dd',
  PhoneVolume = '\uf2a0',
  PhotoFilm = '\uf87c',
  Php = '\uf457',
  PiedPiper = '\uf2ae',
  PiedPiperAlt = '\uf1a8',
  PiedPiperHat = '\uf4e5',
  PiedPiperPp = '\uf1a7',
  PiggyBank = '\uf4d3',
  Pills = '\uf484',
  Pinterest = '\uf0d2',
  PinterestP = '\uf231',
  Pix = '\ue43a',
  PizzaSlice = '\uf818',
  PlaceOfWorship = '\uf67f',
  Plane = '\uf072',
  PlaneArrival = '\uf5af',
  PlaneCircleCheck = '\ue555',
  PlaneCircleExclamation = '\ue556',
  PlaneCircleXmark = '\ue557',
  PlaneDeparture = '\uf5b0',
  PlaneLock = '\ue558',
  PlaneSlash = '\ue069',
  PlaneUp = '\ue22d',
  PlantWilt = '\ue5aa',
  PlateWheat = '\ue55a',
  Play = '\uf04b',
  Playstation = '\uf3df',
  Plug = '\uf1e6',
  PlugCircleBolt = '\ue55b',
  PlugCircleCheck = '\ue55c',
  PlugCircleExclamation = '\ue55d',
  PlugCircleMinus = '\ue55e',
  PlugCirclePlus = '\ue55f',
  PlugCircleXmark = '\ue560',
  Plus = '\u002b',
  PlusMinus = '\ue43c',
  Podcast = '\uf2ce',
  Poo = '\uf2fe',
  PooStorm = '\uf75a',
  Poop = '\uf619',
  PowerOff = '\uf011',
  Prescription = '\uf5b1',
  PrescriptionBottle = '\uf485',
  PrescriptionBottleMedical = '\uf486',
  Print = '\uf02f',
  ProductHunt = '\uf288',
  PumpMedical = '\ue06a',
  PumpSoap = '\ue06b',
  Pushed = '\uf3e1',
  PuzzlePiece = '\uf12e',
  Python = '\uf3e2',
  Q = '\u0051',
  Qq = '\uf1d6',
  Qrcode = '\uf029',
  Question = '\u003f',
  Quinscape = '\uf459',
  Quora = '\uf2c4',
  QuoteLeft = '\uf10d',
  QuoteRight = '\uf10e',
  R = '\u0052',
  RProject = '\uf4f7',
  Radiation = '\uf7b9',
  Radio = '\uf8d7',
  Rainbow = '\uf75b',
  RankingStar = '\ue561',
  RaspberryPi = '\uf7bb',
  Ravelry = '\uf2d9',
  React = '\uf41b',
  Reacteurope = '\uf75d',
  Readme = '\uf4d5',
  Rebel = '\uf1d0',
  Receipt = '\uf543',
  RecordVinyl = '\uf8d9',
  RectangleAd = '\uf641',
  RectangleList = '\uf022',
  RectangleXmark = '\uf410',
  Recycle = '\uf1b8',
  RedRiver = '\uf3e3',
  Reddit = '\uf1a1',
  RedditAlien = '\uf281',
  Redhat = '\uf7bc',
  Registered = '\uf25d',
  Renren = '\uf18b',
  Repeat = '\uf363',
  Reply = '\uf3e5',
  ReplyAll = '\uf122',
  Replyd = '\uf3e6',
  Republican = '\uf75e',
  Researchgate = '\uf4f8',
  Resolving = '\uf3e7',
  Restroom = '\uf7bd',
  Retweet = '\uf079',
  Rev = '\uf5b2',
  Ribbon = '\uf4d6',
  RightFromBracket = '\uf2f5',
  RightLeft = '\uf362',
  RightLong = '\uf30b',
  RightToBracket = '\uf2f6',
  Ring = '\uf70b',
  Road = '\uf018',
  RoadBarrier = '\ue562',
  RoadBridge = '\ue563',
  RoadCircleCheck = '\ue564',
  RoadCircleExclamation = '\ue565',
  RoadCircleXmark = '\ue566',
  RoadLock = '\ue567',
  RoadSpikes = '\ue568',
  Robot = '\uf544',
  Rocket = '\uf135',
  Rocketchat = '\uf3e8',
  Rockrms = '\uf3e9',
  Rotate = '\uf2f1',
  RotateLeft = '\uf2ea',
  RotateRight = '\uf2f9',
  Route = '\uf4d7',
  Rss = '\uf09e',
  RubleSign = '\uf158',
  Rug = '\ue569',
  Ruler = '\uf545',
  RulerCombined = '\uf546',
  RulerHorizontal = '\uf547',
  RulerVertical = '\uf548',
  RupeeSign = '\uf156',
  RupiahSign = '\ue23d',
  Rust = '\ue07a',
  S = '\u0053',
  SackDollar = '\uf81d',
  SackXmark = '\ue56a',
  Safari = '\uf267',
  Sailboat = '\ue445',
  Salesforce = '\uf83b',
  Sass = '\uf41e',
  Satellite = '\uf7bf',
  SatelliteDish = '\uf7c0',
  ScaleBalanced = '\uf24e',
  ScaleUnbalanced = '\uf515',
  ScaleUnbalancedFlip = '\uf516',
  Schlix = '\uf3ea',
  School = '\uf549',
  SchoolCircleCheck = '\ue56b',
  SchoolCircleExclamation = '\ue56c',
  SchoolCircleXmark = '\ue56d',
  SchoolFlag = '\ue56e',
  SchoolLock = '\ue56f',
  Scissors = '\uf0c4',
  Screenpal = '\ue570',
  Screwdriver = '\uf54a',
  ScrewdriverWrench = '\uf7d9',
  Scribd = '\uf28a',
  Scroll = '\uf70e',
  ScrollTorah = '\uf6a0',
  SdCard = '\uf7c2',
  Searchengin = '\uf3eb',
  Section = '\ue447',
  Seedling = '\uf4d8',
  Sellcast = '\uf2da',
  Sellsy = '\uf213',
  Server = '\uf233',
  Servicestack = '\uf3ec',
  Shapes = '\uf61f',
  Share = '\uf064',
  ShareFromSquare = '\uf14d',
  ShareNodes = '\uf1e0',
  SheetPlastic = '\ue571',
  ShekelSign = '\uf20b',
  Shield = '\uf132',
  ShieldCat = '\ue572',
  ShieldDog = '\ue573',
  ShieldHalved = '\uf3ed',
  ShieldHeart = '\ue574',
  ShieldVirus = '\ue06c',
  Ship = '\uf21a',
  Shirt = '\uf553',
  Shirtsinbulk = '\uf214',
  ShoePrints = '\uf54b',
  Shop = '\uf54f',
  ShopLock = '\ue4a5',
  ShopSlash = '\ue070',
  Shopify = '\ue057',
  Shopware = '\uf5b5',
  Shower = '\uf2cc',
  Shrimp = '\ue448',
  Shuffle = '\uf074',
  ShuttleSpace = '\uf197',
  SignHanging = '\uf4d9',
  Signal = '\uf012',
  Signature = '\uf5b7',
  SignsPost = '\uf277',
  SimCard = '\uf7c4',
  Simplybuilt = '\uf215',
  Sink = '\ue06d',
  Sistrix = '\uf3ee',
  Sitemap = '\uf0e8',
  Sith = '\uf512',
  Sitrox = '\ue44a',
  Sketch = '\uf7c6',
  Skull = '\uf54c',
  SkullCrossbones = '\uf714',
  Skyatlas = '\uf216',
  Skype = '\uf17e',
  Slack = '\uf198',
  Slash = '\uf715',
  Sleigh = '\uf7cc',
  Sliders = '\uf1de',
  Slideshare = '\uf1e7',
  Smog = '\uf75f',
  Smoking = '\uf48d',
  Snapchat = '\uf2ab',
  Snowflake = '\uf2dc',
  Snowman = '\uf7d0',
  Snowplow = '\uf7d2',
  Soap = '\ue06e',
  Socks = '\uf696',
  SolarPanel = '\uf5ba',
  Sort = '\uf0dc',
  SortDown = '\uf0dd',
  SortUp = '\uf0de',
  Soundcloud = '\uf1be',
  Sourcetree = '\uf7d3',
  Spa = '\uf5bb',
  SpaceAwesome = '\ue5ac',
  SpaghettiMonsterFlying = '\uf67b',
  Speakap = '\uf3f3',
  SpeakerDeck = '\uf83c',
  SpellCheck = '\uf891',
  Spider = '\uf717',
  Spinner = '\uf110',
  Splotch = '\uf5bc',
  Spoon = '\uf2e5',
  Spotify = '\uf1bc',
  SprayCan = '\uf5bd',
  SprayCanSparkles = '\uf5d0',
  Square = '\uf0c8',
  SquareArrowUpRight = '\uf14c',
  SquareBehance = '\uf1b5',
  SquareCaretDown = '\uf150',
  SquareCaretLeft = '\uf191',
  SquareCaretRight = '\uf152',
  SquareCaretUp = '\uf151',
  SquareCheck = '\uf14a',
  SquareDribbble = '\uf397',
  SquareEnvelope = '\uf199',
  SquareFacebook = '\uf082',
  SquareFontAwesome = '\ue5ad',
  SquareFontAwesomeStroke = '\uf35c',
  SquareFull = '\uf45c',
  SquareGit = '\uf1d2',
  SquareGithub = '\uf092',
  SquareGitlab = '\ue5ae',
  SquareGooglePlus = '\uf0d4',
  SquareH = '\uf0fd',
  SquareHackerNews = '\uf3af',
  SquareInstagram = '\ue055',
  SquareJs = '\uf3b9',
  SquareLastfm = '\uf203',
  SquareMinus = '\uf146',
  SquareNfi = '\ue576',
  SquareOdnoklassniki = '\uf264',
  SquareParking = '\uf540',
  SquarePen = '\uf14b',
  SquarePersonConfined = '\ue577',
  SquarePhone = '\uf098',
  SquarePhoneFlip = '\uf87b',
  SquarePiedPiper = '\ue01e',
  SquarePinterest = '\uf0d3',
  SquarePlus = '\uf0fe',
  SquarePollHorizontal = '\uf682',
  SquarePollVertical = '\uf681',
  SquareReddit = '\uf1a2',
  SquareRootVariable = '\uf698',
  SquareRss = '\uf143',
  SquareShareNodes = '\uf1e1',
  SquareSnapchat = '\uf2ad',
  SquareSteam = '\uf1b7',
  SquareTumblr = '\uf174',
  SquareTwitter = '\uf081',
  SquareUpRight = '\uf360',
  SquareViadeo = '\uf2aa',
  SquareVimeo = '\uf194',
  SquareVirus = '\ue578',
  SquareWhatsapp = '\uf40c',
  SquareXing = '\uf169',
  SquareXmark = '\uf2d3',
  SquareYoutube = '\uf431',
  Squarespace = '\uf5be',
  StackExchange = '\uf18d',
  StackOverflow = '\uf16c',
  Stackpath = '\uf842',
  StaffSnake = '\ue579',
  Stairs = '\ue289',
  Stamp = '\uf5bf',
  Stapler = '\ue5af',
  Star = '\uf005',
  StarAndCrescent = '\uf699',
  StarHalf = '\uf089',
  StarHalfStroke = '\uf5c0',
  StarOfDavid = '\uf69a',
  StarOfLife = '\uf621',
  Staylinked = '\uf3f5',
  Steam = '\uf1b6',
  SteamSymbol = '\uf3f6',
  SterlingSign = '\uf154',
  Stethoscope = '\uf0f1',
  StickerMule = '\uf3f7',
  Stop = '\uf04d',
  Stopwatch = '\uf2f2',
  Stopwatch20 = '\ue06f',
  Store = '\uf54e',
  StoreSlash = '\ue071',
  Strava = '\uf428',
  StreetView = '\uf21d',
  Strikethrough = '\uf0cc',
  Stripe = '\uf429',
  StripeS = '\uf42a',
  Stroopwafel = '\uf551',
  Studiovinari = '\uf3f8',
  Stumbleupon = '\uf1a4',
  StumbleuponCircle = '\uf1a3',
  Subscript = '\uf12c',
  Suitcase = '\uf0f2',
  SuitcaseMedical = '\uf0fa',
  SuitcaseRolling = '\uf5c1',
  Sun = '\uf185',
  SunPlantWilt = '\ue57a',
  Superpowers = '\uf2dd',
  Superscript = '\uf12b',
  Supple = '\uf3f9',
  Suse = '\uf7d6',
  Swatchbook = '\uf5c3',
  Swift = '\uf8e1',
  Symfony = '\uf83d',
  Synagogue = '\uf69b',
  Syringe = '\uf48e',
  T = '\u0054',
  Table = '\uf0ce',
  TableCells = '\uf00a',
  TableCellsLarge = '\uf009',
  TableColumns = '\uf0db',
  TableList = '\uf00b',
  TableTennisPaddleBall = '\uf45d',
  Tablet = '\uf3fb',
  TabletButton = '\uf10a',
  TabletScreenButton = '\uf3fa',
  Tablets = '\uf490',
  TachographDigital = '\uf566',
  Tag = '\uf02b',
  Tags = '\uf02c',
  Tape = '\uf4db',
  Tarp = '\ue57b',
  TarpDroplet = '\ue57c',
  Taxi = '\uf1ba',
  Teamspeak = '\uf4f9',
  Teeth = '\uf62e',
  TeethOpen = '\uf62f',
  Telegram = '\uf2c6',
  TemperatureArrowDown = '\ue03f',
  TemperatureArrowUp = '\ue040',
  TemperatureEmpty = '\uf2cb',
  TemperatureFull = '\uf2c7',
  TemperatureHalf = '\uf2c9',
  TemperatureHigh = '\uf769',
  TemperatureLow = '\uf76b',
  TemperatureQuarter = '\uf2ca',
  TemperatureThreeQuarters = '\uf2c8',
  TencentWeibo = '\uf1d5',
  TengeSign = '\uf7d7',
  Tent = '\ue57d',
  TentArrowDownToLine = '\ue57e',
  TentArrowLeftRight = '\ue57f',
  TentArrowTurnLeft = '\ue580',
  TentArrowsDown = '\ue581',
  Tents = '\ue582',
  Terminal = '\uf120',
  TextHeight = '\uf034',
  TextSlash = '\uf87d',
  TextWidth = '\uf035',
  TheRedYeti = '\uf69d',
  Themeco = '\uf5c6',
  Themeisle = '\uf2b2',
  Thermometer = '\uf491',
  ThinkPeaks = '\uf731',
  ThumbsDown = '\uf165',
  ThumbsUp = '\uf164',
  Thumbtack = '\uf08d',
  Ticket = '\uf145',
  TicketSimple = '\uf3ff',
  Tiktok = '\ue07b',
  Timeline = '\ue29c',
  ToggleOff = '\uf204',
  ToggleOn = '\uf205',
  Toilet = '\uf7d8',
  ToiletPaper = '\uf71e',
  ToiletPaperSlash = '\ue072',
  ToiletPortable = '\ue583',
  ToiletsPortable = '\ue584',
  Toolbox = '\uf552',
  Tooth = '\uf5c9',
  ToriiGate = '\uf6a1',
  Tornado = '\uf76f',
  TowerBroadcast = '\uf519',
  TowerCell = '\ue585',
  TowerObservation = '\ue586',
  Tractor = '\uf722',
  TradeFederation = '\uf513',
  Trademark = '\uf25c',
  TrafficLight = '\uf637',
  Trailer = '\ue041',
  Train = '\uf238',
  TrainSubway = '\uf239',
  TrainTram = '\ue5b4',
  Transgender = '\uf225',
  Trash = '\uf1f8',
  TrashArrowUp = '\uf829',
  TrashCan = '\uf2ed',
  TrashCanArrowUp = '\uf82a',
  Tree = '\uf1bb',
  TreeCity = '\ue587',
  Trello = '\uf181',
  TriangleExclamation = '\uf071',
  Trophy = '\uf091',
  Trowel = '\ue589',
  TrowelBricks = '\ue58a',
  Truck = '\uf0d1',
  TruckArrowRight = '\ue58b',
  TruckDroplet = '\ue58c',
  TruckFast = '\uf48b',
  TruckField = '\ue58d',
  TruckFieldUn = '\ue58e',
  TruckFront = '\ue2b7',
  TruckMedical = '\uf0f9',
  TruckMonster = '\uf63b',
  TruckMoving = '\uf4df',
  TruckPickup = '\uf63c',
  TruckPlane = '\ue58f',
  TruckRampBox = '\uf4de',
  Tty = '\uf1e4',
  Tumblr = '\uf173',
  TurkishLiraSign = '\ue2bb',
  TurnDown = '\uf3be',
  TurnUp = '\uf3bf',
  Tv = '\uf26c',
  Twitch = '\uf1e8',
  Twitter = '\uf099',
  Typo3 = '\uf42b',
  U = '\u0055',
  Uber = '\uf402',
  Ubuntu = '\uf7df',
  Uikit = '\uf403',
  Umbraco = '\uf8e8',
  Umbrella = '\uf0e9',
  UmbrellaBeach = '\uf5ca',
  Uncharted = '\ue084',
  Underline = '\uf0cd',
  Uniregistry = '\uf404',
  Unity = '\ue049',
  UniversalAccess = '\uf29a',
  Unlock = '\uf09c',
  UnlockKeyhole = '\uf13e',
  Unsplash = '\ue07c',
  Untappd = '\uf405',
  UpDown = '\uf338',
  UpDownLeftRight = '\uf0b2',
  UpLong = '\uf30c',
  UpRightAndDownLeftFromCenter = '\uf424',
  UpRightFromSquare = '\uf35d',
  Upload = '\uf093',
  Ups = '\uf7e0',
  Usb = '\uf287',
  User = '\uf007',
  UserAstronaut = '\uf4fb',
  UserCheck = '\uf4fc',
  UserClock = '\uf4fd',
  UserDoctor = '\uf0f0',
  UserGear = '\uf4fe',
  UserGraduate = '\uf501',
  UserGroup = '\uf500',
  UserInjured = '\uf728',
  UserLarge = '\uf406',
  UserLargeSlash = '\uf4fa',
  UserLock = '\uf502',
  UserMinus = '\uf503',
  UserNinja = '\uf504',
  UserNurse = '\uf82f',
  UserPen = '\uf4ff',
  UserPlus = '\uf234',
  UserSecret = '\uf21b',
  UserShield = '\uf505',
  UserSlash = '\uf506',
  UserTag = '\uf507',
  UserTie = '\uf508',
  UserXmark = '\uf235',
  Users = '\uf0c0',
  UsersBetweenLines = '\ue591',
  UsersGear = '\uf509',
  UsersLine = '\ue592',
  UsersRays = '\ue593',
  UsersRectangle = '\ue594',
  UsersSlash = '\ue073',
  UsersViewfinder = '\ue595',
  Usps = '\uf7e1',
  Ussunnah = '\uf407',
  Utensils = '\uf2e7',
  V = '\u0056',
  Vaadin = '\uf408',
  VanShuttle = '\uf5b6',
  Vault = '\ue2c5',
  VectorSquare = '\uf5cb',
  Venus = '\uf221',
  VenusDouble = '\uf226',
  VenusMars = '\uf228',
  Vest = '\ue085',
  VestPatches = '\ue086',
  Viacoin = '\uf237',
  Viadeo = '\uf2a9',
  Vial = '\uf492',
  VialCircleCheck = '\ue596',
  VialVirus = '\ue597',
  Vials = '\uf493',
  Viber = '\uf409',
  Video = '\uf03d',
  VideoSlash = '\uf4e2',
  Vihara = '\uf6a7',
  Vimeo = '\uf40a',
  VimeoV = '\uf27d',
  Vine = '\uf1ca',
  Virus = '\ue074',
  VirusCovid = '\ue4a8',
  VirusCovidSlash = '\ue4a9',
  VirusSlash = '\ue075',
  Viruses = '\ue076',
  Vk = '\uf189',
  Vnv = '\uf40b',
  Voicemail = '\uf897',
  Volcano = '\uf770',
  Volleyball = '\uf45f',
  VolumeHigh = '\uf028',
  VolumeLow = '\uf027',
  VolumeOff = '\uf026',
  VolumeXmark = '\uf6a9',
  VrCardboard = '\uf729',
  Vuejs = '\uf41f',
  W = '\u0057',
  WalkieTalkie = '\uf8ef',
  Wallet = '\uf555',
  WandMagic = '\uf0d0',
  WandMagicSparkles = '\ue2ca',
  WandSparkles = '\uf72b',
  Warehouse = '\uf494',
  WatchmanMonitoring = '\ue087',
  Water = '\uf773',
  WaterLadder = '\uf5c5',
  WaveSquare = '\uf83e',
  Waze = '\uf83f',
  Weebly = '\uf5cc',
  Weibo = '\uf18a',
  WeightHanging = '\uf5cd',
  WeightScale = '\uf496',
  Weixin = '\uf1d7',
  Whatsapp = '\uf232',
  WheatAwn = '\ue2cd',
  WheatAwnCircleExclamation = '\ue598',
  Wheelchair = '\uf193',
  WheelchairMove = '\ue2ce',
  WhiskeyGlass = '\uf7a0',
  Whmcs = '\uf40d',
  Wifi = '\uf1eb',
  WikipediaW = '\uf266',
  Wind = '\uf72e',
  WindowMaximize = '\uf2d0',
  WindowMinimize = '\uf2d1',
  WindowRestore = '\uf2d2',
  Windows = '\uf17a',
  WineBottle = '\uf72f',
  WineGlass = '\uf4e3',
  WineGlassEmpty = '\uf5ce',
  Wirsindhandwerk = '\ue2d0',
  Wix = '\uf5cf',
  WizardsOfTheCoast = '\uf730',
  Wodu = '\ue088',
  WolfPackBattalion = '\uf514',
  WonSign = '\uf159',
  Wordpress = '\uf19a',
  WordpressSimple = '\uf411',
  Worm = '\ue599',
  Wpbeginner = '\uf297',
  Wpexplorer = '\uf2de',
  Wpforms = '\uf298',
  Wpressr = '\uf3e4',
  Wrench = '\uf0ad',
  X = '\u0058',
  XRay = '\uf497',
  Xbox = '\uf412',
  Xing = '\uf168',
  Xmark = '\uf00d',
  XmarksLines = '\ue59a',
  Y = '\u0059',
  YCombinator = '\uf23b',
  Yahoo = '\uf19e',
  Yammer = '\uf840',
  Yandex = '\uf413',
  YandexInternational = '\uf414',
  Yarn = '\uf7e3',
  Yelp = '\uf1e9',
  YenSign = '\uf157',
  YinYang = '\uf6ad',
  Yoast = '\uf2b1',
  Youtube = '\uf167',
  Z = '\u005a',
  Zhihu = '\uf63f'
}
