import React, { useState } from 'react'
import { Button } from '../button/button'
import './uploadfield.scss'

export interface UploadFieldProps {
  label?: string;
  buttonLabel?: string;
  hoverLabel?: string;
  className?: string;
  onFile: (file: File) => void;
}

export const UploadField = ({
  label = 'Drag your file here',
  buttonLabel = 'Browse your files',
  hoverLabel = 'Drop your file',
  onFile,
  className = '',
  ...rest
}: UploadFieldProps) => {
  const [dropActive, setDropActive] = useState(false)
  const input = React.useRef(null)

  return (
    <div
      className={`bepe-uploadfield bepe-uploadfield--${
        dropActive ? 'active' : 'inactive'
      } ${className}`}
      onDragOver={(event) => {
        event.preventDefault() // preventing from default behaviour
        setDropActive(true)
      }}
      onDragLeave={() => {
        event.preventDefault() // preventing from default behaviour
        setDropActive(false)
      }}
      onDrop={(event) => {
        event.preventDefault() // preventing from default behaviour
        // getting user select file and [0] this means if user select multiple files then we'll select only the first one
        const file = event.dataTransfer.files[0]
        setDropActive(false)
        onFile(file)
      }}
      {...rest}
    >
      <h2>{dropActive ? hoverLabel : label}</h2>
      <Button
        classtype="primary"
        onClick={() => {
          if (input.current) {
            (input.current as HTMLButtonElement).click()
          }
        }}
      >
        {buttonLabel}
      </Button>
      <input
        ref={input}
        type="file"
        hidden
        onChange={() => {
          if (input.current) {
            const newFiles = (input.current as HTMLInputElement).files
            if (newFiles) {
              onFile(newFiles[0])
            }
          }
          setDropActive(false)
        }}
      />
    </div>
  )
}
