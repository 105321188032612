import React, { InputHTMLAttributes, ReactNode } from 'react'
import { Icon } from '../../../display/icon/icon'
import './radiobutton.scss'

export interface RadioButtonProps<T> extends InputHTMLAttributes<HTMLInputElement> {
  radioGroup: string;
  label?: string;
  radioId: string;
  data?: T;
  selected: boolean;
  disabled?: boolean;
  onSelected: (key: string, value: T | string) => void;
  children?: ReactNode | Array<ReactNode>
  className?: string;
}

export const RadioButton = <T, >({
  radioGroup,
  children = 'default',
  radioId,
  data,
  selected,
  disabled,
  onSelected,
  className = '',
  ...rest
}: RadioButtonProps<T>) => {
  const realData = data ?? radioId
  const htmlId = `${radioGroup}-${radioId}`

  return (
    <div className={`bepe-radiobutton bepe-radiobutton--${selected ? 'selected' : 'unselected'} bepe-radiobutton--${disabled ? 'disabled' : 'enabled'} ${className}`}>
      <input
        type="radio"
        checked={selected}
        onChange={evt => { if (evt.target.checked) { onSelected(radioId, realData) } }}
        name={radioGroup}
        id={htmlId}
        value={radioId}
        disabled={disabled}
        {...rest} />
      <label className="interactive" htmlFor={htmlId}>{children}
        <Icon className="bepe-radiobutton__selected-icon" icon={'\uf058'}></Icon>
        <div className="bepe-radiobutton__unselected-icon"></div>
      </label>
    </div>
  )
}
