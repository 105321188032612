import React, { HTMLProps } from 'react'
import './spinner.scss'

export interface SpinnerProps extends HTMLProps<HTMLDivElement> {
  visible?: boolean;
  sizeAsString?: string;
  className?: string;
}

export const Spinner = ({
  visible = true,
  sizeAsString = '10rem',
  className = '',
  ...props
}: SpinnerProps) => {
  return (
    <div
      {...props}
      className={`bepe-spinner bepe-spinner--${
        visible ? 'visible' : 'hidden'
      } ${className}`}
      style={{
        width: sizeAsString,
        height: sizeAsString,
        fontSize: sizeAsString
      }}
    >
      <div className="bepe-spinner__circle">
        <div className="bepe-spinner__circle-wrapper bepe-spinner__circle-wrapper--top">
          <div className="bepe-spinner__circle-inner"></div>
        </div>
        <div className="bepe-spinner__circle-wrapper bepe-spinner__circle-wrapper--bottom">
          <div className="bepe-spinner__circle-inner"></div>
        </div>
      </div>
    </div>
  )
}
