import React, { ReactNode } from 'react'
import './formelement.scss'
import { Signal } from '../../util/signal'

export interface FormElementProperties {
  id?: string;
  label?: string;
  hint?: string;
  signal?: Signal | null;
}

export interface FormElementProps extends FormElementProperties {
  className?: string;
  children?: ReactNode | Array<ReactNode>;
}

export const FormElement = ({
  id,
  label,
  hint,
  signal,
  children,
  className = ''
}: FormElementProps) => {
  const usedId = id || label

  return (
    <div className={`bepe-form-element bepe-form-element--${signal || 'normal'} bepe-form-element--${label ? 'with-label' : 'without-label'} ${className}`}>
      {children}
      {label && <label htmlFor={usedId} className="bepe-form-element__label">{label}</label>}
      {hint && <div className="bepe-form-element__hint">{hint}</div>}
    </div>
  )
}
