import React, { HTMLProps } from 'react'
import './icon.scss'

export enum IconStyle {
  Brands = 'brands',
  Solid = 'solid',
  Regular = 'regular'
}

export interface IconProps extends HTMLProps<HTMLDivElement> {
  icon: string;
  iconStyle?: IconStyle,
  className?: string;
}

export const Icon = ({
  icon,
  iconStyle = IconStyle.Solid,
  className = '',
  ...props
}: IconProps) => {
  return (
    <div {...props} className={`bepe-icon bepe-icon--${iconStyle} ${className}`}>
      {icon}
    </div>
  )
}
