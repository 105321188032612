import React, { ChangeEvent, InputHTMLAttributes, KeyboardEventHandler } from 'react'
import { useCombinedRefs } from '../../../hooks/useCombinedRefs'
import { Icon } from '../../display/icon/icon'
import { Icons } from '../../display/icon/Icons'
import { Signal } from '../../util/signal'
import { FormElement } from '../formelement/formelement'
import './textfield.scss'

export interface TextfieldProps extends InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  label?: string;
  hint?: string;
  signal?: Signal | null;
  enableEmptyButton?: boolean;
  onEmpty?: () => void;
  className?: string;
  onEnter?: KeyboardEventHandler<HTMLInputElement>;
}

export const Textfield = React.forwardRef<HTMLInputElement, TextfieldProps>(function Textfield ({
  id,
  label,
  hint,
  signal,
  enableEmptyButton = true,
  className = '',
  placeholder = '',
  onEnter,
  onKeyDown,
  onChange,
  onEmpty = undefined,
  ...rest
}: TextfieldProps, ref) {
  const usedId = id || label
  const innerRef = React.useRef(null)
  const combinedRef = useCombinedRefs(ref, innerRef)
  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (evt) => {
    if (evt.key === 'Enter' && onEnter) {
      onEnter(evt)
      evt.stopPropagation()
    } else if (onKeyDown) {
      onKeyDown(evt)
    }
  }

  const simulateOnChange = (value: string) => {
    const target = innerRef.current ? innerRef.current : {}
    if (onChange) {
      onChange({
        stopPropagation: () => {},
        preventDefault: () => {},
        target: { ...target, value }
      } as unknown as ChangeEvent<HTMLInputElement>)
    }
  }

  const onDeleteContent = () => {
    if (!rest.disabled) {
      if (onEmpty) {
        onEmpty()
      } else {
        simulateOnChange('')
      }
      if (innerRef.current) {
        innerRef.current.focus()
      }
    }
  }

  return (
    <FormElement id={usedId} label={label} hint={hint} signal={signal} className={`bepe-textfield bepe-textfield--${rest.disabled ? 'disabled' : 'enabled'} bepe-textfield--${enableEmptyButton ? 'with-empty-button' : 'without-empty-button'} ${className}`}>
      <div className='bepe-textfield__container'>
        <input
          {...rest}
          ref={combinedRef}
          id={usedId}
          className={'bepe-textfield__input'}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          onChange={onChange}
        />
        {enableEmptyButton && <Icon className='bepe-textfield__delete-button' icon={Icons.Xmark} onClick={onDeleteContent}></Icon>}
      </div>
    </FormElement>
  )
})
