export enum TextSize {
  XS = '50',
  S = '75',
  M = '100',
}

export enum HeadingSize {
  XXS = '25',
  XS = '50',
  S = '75',
  M = '100',
  L = '150',
}
