import React, { ReactNode } from 'react'
import './backdrop.scss'

export interface BackdropProps {
    onClose?: () => void
    className?: string,
    children?: ReactNode | Array<ReactNode>
}

export const Backdrop = ({
  onClose,
  className,
  children
}: BackdropProps) => {
  return (
    <div
    className={`${className || ''} bepe-backdrop`}
    onClick={(event) => {
      event.preventDefault()
      event.stopPropagation()
      if (event.currentTarget === event.target && onClose) {
        onClose()
      }
    }}
    >
    {children}
    </div>
  )
}
